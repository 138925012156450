import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import intersect from '@alpinejs/intersect'
import mask from '@alpinejs/mask'
import Tooltip from '@ryangjchandler/alpine-tooltip'
import { Modal, ModalOpener } from "./components/Modal"
import TestDriveForm from "./components/TestDriveForm"
import { MegaMenu, MegaMenuOpener, MegaMenuSubHeader } from "./components/MegaMenu"
import { setCookie } from "./utils/Cookies"
import { AppSearch } from "./components/Search"
import { EntriesGrid } from "./components/EntriesGrid"
import { FormRange } from "./components/FormRange"
import { CompareLinkToggle, CompareTable, CompareToggle } from "./components/Compare"
import { FavoriteLinkToggle, FavoriteList, FavoriteToggle } from "./components/Favorite"
import { CollectionLink, Collections } from "./components/Collections"
import {TeamMemberContact, TeamVCardCode, TeamVCardDownload} from "./components/Team";
import FormPhone from "./components/FormPhone";
import printScripts from "./print";
import homeScripts from "./home";
import archiveScripts from "./archive";
import offersScripts from "./offers";

//import.meta.glob([ '@images/**' ])
if (import.meta.env.DEV) {
    import("@styles/main.sass");
    import(`@styles/${document.body.dataset.site}.sass`)
}

declare global {
    const dataLayer: any

    interface Window {
        Alpine: any
        openOfferGallery: any
    }
}

/**
 * Separate scripts includes
 */

printScripts();

document.body.classList.contains('page-template-template-homepage') && homeScripts();
(document.body.classList.contains('post-type-archive-offers') || document.body.classList.contains('blog')) && archiveScripts();
document.body.classList.contains('single-offers') && offersScripts();

/**
 * AlpineJS plugin registration
 */
Alpine.plugin(persist)
Alpine.plugin(intersect)
Alpine.plugin(mask)
Alpine.plugin(Tooltip)

window.Alpine = Alpine

/**
 * AlpineJS data components
 */
Alpine.data('MegaMenu', MegaMenu)
Alpine.data('AppSearch', AppSearch)
Alpine.data('EntriesGrid', EntriesGrid);
Alpine.data('FormRange', FormRange)
Alpine.data('CollectionLink', CollectionLink)
Alpine.data('FavoriteLinkToggle', FavoriteLinkToggle)
Alpine.data('CompareLinkToggle', CompareLinkToggle)
Alpine.data('CompareTable', CompareTable)
Alpine.data('FavoriteList', FavoriteList)
Alpine.data('TestDriveForm', TestDriveForm)
Alpine.data('Modal', Modal)
Alpine.data('FormPhone', FormPhone)

/**
 * AlpineJS stores
 */
Alpine.store('Collections', Collections)

/**
 * Default page load functions
 */
document.addEventListener("DOMContentLoaded", () => {
    (() => { // Hiding header
        const header = document.querySelector('[data-app-header]') as HTMLElement;
        if (!header) return

        const headerOffset = header.offsetTop - window.scrollY,
            headerHeight = (header.children[0] as HTMLElement)?.offsetHeight ?? 0;

        let lastScroll = 0;

        const hidingOnScroll = () => {
            const currentScroll = window.scrollY;

            if (currentScroll > headerOffset + headerHeight) {
                header.classList.add('is-sticked')
                header.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
                document.body.classList[currentScroll > lastScroll ? 'remove' : 'add']('is-sticked-header')
            } else {
                header.classList.remove('is-sticked')
                header.classList.remove('is-up')
                document.body.classList.remove('is-sticked-header')
            }

            lastScroll = currentScroll;
        }

        hidingOnScroll();
        window.addEventListener('scroll', () => hidingOnScroll(), { passive: true });

        const brandsLogos = header.querySelector('.CompanyLogos--collapsable > .CompanyLogos-brands')
        if (!brandsLogos || window.innerWidth >= 768) return

        const brandsClone = brandsLogos.cloneNode(true) as HTMLElement
        brandsClone.addEventListener('click', e => {
            e.target === brandsClone && document.body.classList.remove('is-companyLogosOpened')
        })

        brandsLogos.insertAdjacentElement('afterend', brandsClone)
        brandsLogos.addEventListener('click', e => {
            if (window.innerWidth >= 768) return

            e.preventDefault()
            document.body.classList.add('is-companyLogosOpened')
        })
    })();

    (() => {
        const btn = document.querySelector('[data-scroll-next]');
        btn && btn.addEventListener('click', () => {
            btn.closest('section').nextElementSibling.scrollIntoView()
        })
    })();

    (() => { // Scroll to top button
        const btn = document.querySelector('[data-scroll-top]') as HTMLElement
        if (!btn || document.body.classList.contains('single-offers')) return

        const footerHeight = document.querySelector('footer')?.offsetHeight ?? 0
        const toggleButtonVisibility = () => {
            btn.toggleAttribute('disabled', window.scrollY < 480
                || document.body.scrollHeight < 2.4 * footerHeight)

            !document.body.classList.contains('is-mobile') && btn.style.setProperty('--scrollToTopBottomOffset',
                `${Math.max(Math.min(document.body.scrollHeight - window.innerHeight - window.scrollY, 66), 0) - 66}px`)
        }

        toggleButtonVisibility()
        window.addEventListener('scroll', () => toggleButtonVisibility(), { passive: true })

        btn.addEventListener('click', () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }))
    })();

    (() => { // pageContent gallery
        const galleryOptions = {
            licenseKey: "09B33A994193E54AB538C7C9C1853E23",
            speed: 400,
            getCaptionFromTitleOrAlt: false,
            mobileSettings: {
                showCloseIcon: true
            }
        }

        const images = document.querySelectorAll('.wp-block-image:has(a[href*="/uploads/"])');
        images.forEach((image: HTMLElement) => !image.closest('.wp-block-gallery')
            && lightGallery(image, {
                ...galleryOptions,
                selector: 'a',
                download: false
            }))

        const galleries = document.querySelectorAll('.wp-block-gallery');
        galleries.forEach((gallery: HTMLElement) => lightGallery(gallery, {
            ...galleryOptions,
            selector: 'figure > a',
            download: gallery.hasAttribute('data-downloadable'),
            plugins: [lgThumbnail]
        }));
    })();

    (() => { // UTM campaign parameters
        const urlParams = new URLSearchParams(window.location.search)
        document.querySelectorAll('input[type="hidden"][data-utm]')
            .forEach((input: HTMLInputElement) => input.value = urlParams.get(input.dataset.utm))
    })();

    (() => { // DataLayer submit
        window.hasOwnProperty('html_forms') && html_forms?.on('success', (formElement) => {
            if (!formElement || !window.dataLayer) return

            const formDetails = {},
                formData = Object.fromEntries((new FormData(formElement)).entries())

            formDetails.dealership = formData.dealerstvi ?? ''

            if (formElement.dataset.slug === 'offer-interest') {
                formDetails.priceValue = parseInt(formData["cena-vozidla"])
                formDetails.priceCurrency = 'CZK'
            } else {
                formDetails.priceValue = formDetails.priceCurrency = ''
            }

            const eventData = {
                event: 'form-send',
                formID: formElement.dataset.slug,
                formTitle: formElement.dataset.title,
                pageUrl: window.location.href,
                ...formDetails
            }

            dataLayer?.push(eventData)
            formData.financovani && dataLayer?.push({...eventData, formTitle: `${eventData.formTitle} s financováním`})
        });
    })();
});

/* Alpine.js init */
document.addEventListener('alpine:init', () => {
    Alpine.bind('InfoBarCloser', {
        '@click'() {
            const key = this.$el.dataset.key ?? '0';
            setCookie(`AppInfoBar-${key}`, 'closed', 365)
            this.$el.closest('.AppInfoBar')?.remove()
        }
    })

    Alpine.bind('AppNavOpener', {
        '@click.prevent'() {
            document.body.classList.toggle('u-hiddenOverflow');
            this.$el.closest('[data-app-header]')?.classList.toggle('is-opened');
            this.$dispatch('open-megamenu')
        }
    })

    Alpine.bind('AppBackLink',
        document.body.classList.contains('single-offers')
        || document.body.classList.contains('porovnani')
        || document.body.classList.contains('oblibene') ? {
            ':href'() {
                const url = (this.filterUrl ?? null)?.trim()
                return url ? url : this.$el.href }
        } : {})

    Alpine.bind('MegaMenuOpener', MegaMenuOpener)
    Alpine.bind('MegaMenuSubHeader', MegaMenuSubHeader)
    Alpine.bind('FavoriteToggle', FavoriteToggle)
    Alpine.bind('CompareToggle', CompareToggle)
    Alpine.bind('ModalOpener', ModalOpener)
    Alpine.bind('TeamMemberContact', TeamMemberContact)
    Alpine.bind('TeamVCard', {
        ...TeamVCardDownload,
        ...(!document.body.classList.contains('is-mobile') ? TeamVCardCode : {})
    })
})

Alpine.start();

(() => {
    if (!document.body.classList.contains('page-template-template-guide')) return

    document.querySelectorAll<HTMLElement>('[data-guide-link]').forEach(link => {
        const video = link.querySelector('video')
        if (!video) return

        link.addEventListener("mouseenter", () => video.play().then(() => link.classList.add('is-playing')))
        link.addEventListener("mouseleave", () => {
            link.classList.remove('is-playing')
            setTimeout(() => {
                if (link.classList.contains('is-playing')) return
                video.pause()
                video.currentTime = 0
            }, 640)
        })
    })
})();