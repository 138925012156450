import Swiper, { Navigation } from "swiper"
import lightGallery from "lightgallery";
import lgVideo from 'lightgallery/plugins/video'
import lgZoom from "lightgallery/plugins/zoom";
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import { setCookie, getCookie } from "./utils/Cookies"
import Alpine from "alpinejs"

declare global {
    const offerFinancing: Object[]
}

const offersActiveTabsStorage = {
    getItem(key) {
        let activeTabs = sessionStorage.getItem('offersActiveTabs')
        if (!activeTabs) return null

        activeTabs = JSON.parse(activeTabs)
        return activeTabs[key] ?? null
    },
    setItem(key, value) {
        const activeTabs = {...(JSON.parse(sessionStorage.getItem('offersActiveTabs') ?? '{}')), [`${key}`]: value }
        sessionStorage.setItem('offersActiveTabs', JSON.stringify(activeTabs))
    }
}

export default function () {

    document.addEventListener('alpine:init', () => {
        // Offer single data
        Alpine.data('OfferSingle', (offerId) => ({
            activeSection: null,
            sections: [],
            section: {
                ['x-init']() { this.sections.push({ 'id': this.$el.id, 'title': this.$el.dataset.sectionTitle }) },
                ['x-intersect.margin.-48%']() { this.activeSection = this.$el.id }
            },
            sectionsNav: {},
            sectionsNavLink: {
                [':href']() {
                    return `#${this.offerSection.id}`
                },
                [':class']() {
                    return { 'is-active': this.offerSection.id === this.activeSection }
                },
                ['x-html']() {
                    return `<span>${this.offerSection.title}</span>`
                }
            },

            filterUrl: Alpine.$persist('').as('offersFilterUrl').using(sessionStorage),

            activeTab: Alpine.$persist('form').as(`${offerId}`).using(offersActiveTabsStorage),
            get activeTabIndex() {
                return [...this.$refs.tabs.children].indexOf(this.$refs.tabs.querySelector(`button[data-name='${this.activeTab}']`)) ?? 0
            },
            activeTabIndicator: {
                [':style']() { return { '--offerTabsCount': this.$el.dataset.tabsCount ?? 2, '--offerTabsActive': this.activeTabIndex }}
            },
            tabToggle: {
                [':class']() { return this.activeTab === this.$el.dataset.name && 'is-active' },
                ['@click.prevent']() { this.activeTab = this.$el.dataset.name }
            },
            tabContent: {
                [':class']() { return this.activeTab === this.$el.dataset.name && 'is-active' }
            },
            changeActiveTab: {
                ['@click']() {
                    const targetTab = this.$el.dataset.tabName
                    if (!targetTab) return

                    (this.$el.dataset?.open ?? '') === 'financing' && this.$dispatch('open-financing')
                    this.activeTab = targetTab
                }
            }
        }));

        // Offer features group
        Alpine.data('OfferFeatures', () => ({
            allOpened: false,
            allFeaturesNoContent: false,
            visibleFeatures: 8,
            get featuresWithContent() {
                return this.$refs.features?.querySelectorAll('details:not(.no-content)') ?? []
            },
            featureSummary: {
                ['@click']() {
                    this.$nextTick(() => {
                        this.allOpened = Array.from(this.featuresWithContent)
                            .every((feature: HTMLDetailsElement) => feature.open)
                    })
                }
            },
            openAllFeatures: {
                ['x-show']() { return this.featuresWithContent.length },
                [':class']() { return this.allOpened && 'is-opened' },
                ['@click.prevent']() {
                    this.allOpened = !this.allOpened
                    this.featuresWithContent.forEach((feature: HTMLDetailsElement) => { feature.open = this.allOpened })
                    this.visibleFeatures = this.$refs.features.dataset.count
                }
            },
            showAllFeatures: {
                ['@click']() { this.visibleFeatures = this.$refs.features.dataset.count }
            }
        }))

        // Offer interest form with financing
        Alpine.data('OfferInterestForm', () => ({
            financingData: undefined,
            financingOptions: undefined,
            financovani: true,
            financovaniTyp: 0,
            financovaniAkontace: 0,
            financovaniNajezd: 0,
            financovaniDelka: 0,
            financovaniPosledniSplatka: 0,
            financovaniPojisteni: 'ano',
            financovaniPlatceDph: 'ano',
            protiucet: true,

            compareVariant(variant) {
                return parseInt(variant.n_typ_financovani) === this.financovaniTyp
                    && parseInt(variant.n_delka) === this.financingOptions.delka[this.financovaniDelka]
                    && ((this.financovaniTyp === 0 && parseInt(variant.n_akontace_procento) === this.financingOptions.akontace[this.financovaniAkontace])
                        || (this.financovaniTyp === 1 && parseInt(variant.n_rocni_najezd) === this.financingOptions.najezd[this.financovaniNajezd]))
            },

            get financingResult() {
                return this.financingData?.find(variant => {
                    return this.compareVariant(variant) && (this.financovaniTyp === 1 || parseInt(variant.n_PNS_procento) === this.financingOptions.posledniSplatka[this.financovaniPosledniSplatka])
                })
            },

            get financingPayment() {
                return this.financingResult ? parseInt(this.financingResult[this.financovaniPojisteni !== 'ano' || this.financovaniTyp === 1 ? 'n_splatka'
                    : this.financovaniPlatceDph !== 'ano' ? 'n_splatka_2' : 'n_splatka_3']) : 0;
            },

            get financingLastPayment() {
                return this.financingResult ? parseInt(this.financingResult['n_PNS_castka']) : 0;
            },

            get financovaniVysledek() {
                const result = {};
                if (this.financovaniTyp === 0) {
                    result.akontace = `${parseInt(this.financingResult?.n_akontace_castka ?? '0').toLocaleString()} Kč`
                    result['posledni-splatka'] = `${parseInt(this.financingResult?.n_PNS_castka ?? '0').toLocaleString()} Kč`
                } else if (this.financovaniTyp === 1) {
                    result.najezd = `${(this.financingOptions?.najezd[this.financovaniNajezd]).toLocaleString()} km`
                }

                return JSON.stringify({
                    typ: this.financovaniTyp === 0 ? 'Úvěr' : 'Operativní leasing',
                    ...result,
                    doba: `${this.financingOptions?.delka[this.financovaniDelka]} měsíců`,
                    splatka: `${this.financingPayment.toLocaleString()} Kč`
                })
            },

            setupFinancingBasedOnType() {
                this.financingOptions = { typ: [], akontace: [], najezd: [], delka: [], posledniSplatka: [] }

                this.financingData.forEach(variant => {
                    const variantValues = {
                        typ: parseInt(variant.n_typ_financovani),
                        najezd: parseInt(variant.n_rocni_najezd),
                        akontace: parseInt(variant.n_akontace_procento),
                        delka: parseInt(variant.n_delka)
                    }

                    Object.entries(variantValues).forEach(([key, value]) => {
                        !this.financingOptions[key].includes(value) && this.financingOptions[key].push(value)
                    })
                })

                Object.entries(this.financingOptions).forEach(([key, options]) => this.financingOptions[key].sort())

                this.financovaniAkontace = Math.max(this.financingOptions.akontace.length - 1, 0)
                this.$dispatch('range-change', { name: 'akontace' })

                this.financovaniDelka = Math.max(this.financingOptions.delka.length - 1, 0)
                this.$dispatch('range-change', { name: 'doba' })
            },

            init() {
                if(!offerFinancing || (offerFinancing && !offerFinancing.length))
                    return

                this.financingData = offerFinancing
                this.setupFinancingBasedOnType()

                this.financovaniTyp = this.financingOptions.typ[0]

                Alpine.effect(() => {
                    const availableOptions = this.financingData?.filter(variant => this.compareVariant(variant))
                        .map(variant => parseInt(variant.n_PNS_procento)).sort()

                    this.financingOptions.posledniSplatka = [];
                    (availableOptions ?? []).forEach(option => {
                        !this.financingOptions['posledniSplatka'].includes(option) && this.financingOptions['posledniSplatka'].push(option)
                    })

                    if (this.financingOptions.posledniSplatka.length && this.financingOptions.posledniSplatka.length < this.financovaniPosledniSplatka + 1) {
                        this.financovaniPosledniSplatka = 0
                        this.$dispatch('range-change', { name: 'posledni-splatka' })
                    }

                    this.$dispatch('range-change')
                })
            },

            toggleFinancovaniTyp: {
                [':disabled']() { return !this.financingOptions.typ.includes(parseInt(this.$el.value)) }
            },

            rangeFinancovaniAkontace: {
                [':min']() { return 0 },
                [':max']() { return (this.financingOptions?.akontace.length ?? 1) - 1 },
                [':step']() { return 1 }
            },
            rangeFinancovaniAkontaceResult: {
                ['x-html']() { return `<strong>${parseInt(this.financingResult?.n_akontace_castka ?? '0').toLocaleString()}</strong> Kč` }
            },

            rangeFinancovaniNajezd: {
                [':min']() { return 0 },
                [':max']() { return (this.financingOptions?.najezd.length ?? 1) - 1 },
                [':step']() { return 1 }
            },
            rangeFinancovaniNajezdResult: {
                ['x-html']() { return `<strong>${(this.financingOptions?.najezd[this.financovaniNajezd]).toLocaleString()}</strong> km` }
            },

            rangeFinancovaniDelka: {
                [':min']() { return 0 },
                [':max']() { return (this.financingOptions?.delka.length ?? 1) - 1 },
                [':step']() { return 1 }
            },
            rangeFinancovaniDelkaResult: {
                ['x-html']() { return `<strong>${this.financingOptions?.delka[this.financovaniDelka]}</strong> měsíců` }
            },

            rangeFinancovaniPosledniSplatka: {
                [':min']() { return 0 },
                [':max']() { return (this.financingOptions?.posledniSplatka.length ?? 1) - 1 },
                [':step']() { return 1 },
                [':disabled']() { return !(this.financingOptions?.posledniSplatka?.length > 1) },
            },
            rangeFinancovaniPosledniSplatkaResult: {
                ['x-html']() { return `<strong>${parseInt(this.financingResult?.n_PNS_castka ?? '0').toLocaleString()}</strong> Kč` }
            },

            financovaniToggle: {
                ['x-on:open-financing.window']() { this.financovani = true },
            },
            financingPaymentText: {
                ['x-text']() { return this.financingPayment.toLocaleString() }
            },
            financingPaymentTextAdd: {
                ['x-text']() { return this.financingLastPayment.toLocaleString() }
            }
        }));
    });

    (() => { // Photo gallery
        const gallery = document.querySelector('[data-gallery-swiper]') as HTMLElement
        window.openOfferGallery = (index) => {
            gallery && galleryInstance.openGallery(index);
        }

        if (!gallery) return;

        const galleryEl = gallery?.querySelector('.swiper-wrapper') as HTMLElement
        const galleryInstance = lightGallery(galleryEl, {
            licenseKey: "09B33A994193E54AB538C7C9C1853E23",
            speed: 400,
            enableDrag: false,
            download: false,
            getCaptionFromTitleOrAlt: false,
            mobileSettings: {
                showCloseIcon: true
            },
            plugins: [lgVideo, lgThumbnail, lgZoom]
        });

        const gallerySwiper = new Swiper(gallery, {
            slidesPerView: 'auto',
            cssMode: true,
            modules: [ Navigation ],
            breakpoints: {
                768: {
                    slidesPerView: 3
                },
                1280: {
                    slidesPerView: 4
                },
                2048: {
                    slidesPerView: 5
                }
            },
            navigation: {
                nextEl: '[data-gallery-next]',
                prevEl: '[data-gallery-prev]',
            },
        });

        gallery.addEventListener('click', e => {
            const target = e.target as HTMLElement
            if ( !target.matches('a.swiper-slide-duplicate[data-lg-id]') )
                return

            e.preventDefault()
            window.openOfferGallery(parseInt(target.dataset.swiperSlideIndex))
        })
    })();

    (() => { // Last viewed offers
        if (!document.body.classList.contains('single-offers'))
            return;

        const offerID = Array.from(document.body.classList).find(el => el.startsWith('postid-')).replace('postid-', '');

        let cookieArray = [];
        if (getCookie('LastViewedOffers') !== '') {
            cookieArray = getCookie('LastViewedOffers').split(';');
        }

        cookieArray = cookieArray.filter(offer => offer !== offerID);
        cookieArray.unshift(offerID);

        setCookie('LastViewedOffers', cookieArray.splice(0, 7).join(';'), 365);
    })();

    (() => { // Sticky summary bar
        const stickyBar = document.querySelector('[data-sticky-summary-bar]')
        if (!stickyBar || !document.body.classList.contains('is-mobile'))
            return

        const toggleStickyBar = () => {
            const startPoint = document.querySelector('.OfferHeroParameters')?.getBoundingClientRect().top,
                endPoint = document.querySelector('.NewsletterRow')?.getBoundingClientRect().top

            stickyBar.classList.toggle('is-sticked', startPoint < 0 && endPoint > window.innerHeight)
        }

        window.addEventListener('scroll', () => toggleStickyBar(), { passive: true })
        toggleStickyBar()
    })();

    (async () => { // Sellers exposure countUp
        document.querySelectorAll('.OfferTabs-content[data-name="form"] .OfferSellerCard')
            .forEach(el => {
                const id = el.querySelector<HTMLElement>('[data-id]')?.dataset.id
                id && fetch(`/wp-json/team/seller-exposure/${id}`, { method: "POST" })
            })
    })();
}
